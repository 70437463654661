<template>
    <div class="advanced" :key="$route.params.kind">
        <div class="row">
            <div class="col-lg-8">
                <TextEditorCard
                    class="mb-4"
                    :title="labels[$route.params.kind]"
                    :value="textEditorValue"
                    @changed="blockTextEditorChanged({ value: $event.value, key: $route.params.kind })"
                />
            </div>
            <div class="col-lg-4 mb-4">
                <UploadingCard
                    :value="checkImageInstance($route.params.kind)"
                    :btn-label="labels.upload"
                    @changed="blockImageChanged({ value: $event, key: $route.params.kind })"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TextEditorCard from "@/components/Input/TextEditorCard";
import UploadingCard from "@/components/Input/UploadingCard";

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "Advanced",
    components: {
        TextEditorCard,
        UploadingCard,
    },
    computed: {
        ...mapGetters('helper', ['labels']),
        localMBData() {
            return this.$store.state.monetizationBundle.localMBData;
        },
        textEditorValue() {
            if (
                this.$route.params.kind &&
                this.localMBData[this.$route.params.kind] &&
                this.localMBData[this.$route.params.kind].text
            ) {
                return this.localMBData[this.$route.params.kind].text;
            }
            return "";
        },
    },
    methods: {
        ...mapMutations("monetizationBundle", {
            updateLocalMBDataObject: "updateLocalMBDataObject",
        }),
        checkImageInstance(propName) {
            return this.localMBData[propName] &&
                this.localMBData[propName].image &&
                typeof this.localMBData[propName].image === "string"
                ? this.localMBData[propName].image
                : "";
        },

        blockTextEditorChanged(data) {
            this.updateLocalMBDataObject({ key_0: data.key, key_1: "text", value: data.value });
        },

        blockImageChanged(data) {
            this.updateLocalMBDataObject({ key_0: data.key, key_1: "image", value: data.value });
        },
    },
};
</script>

<style lang="scss" scoped></style>