<template>
	<BaseCard class="uploading-card" padding="0">
		<div class="input-wrapper">
			<FormulateInput
				type="image"
				name="image"
				:value="localValue"
				:uploader="fakeUploader"
				validation="mime:image/jpeg,image/png,image/gif"
				@change="onChangeInput"
				@file-removed="imageRemoved"
			/>
			<Button :label="btnLabel" variant="btn-purple" icon="upload" icon-position="right"/>
		</div>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from "@/components/Buttons/Button.vue";

export default {
	name: 'UploadingCard',
	components: {
		BaseCard,
		Button,
	},
	props: {
		btnLabel: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		editable:{
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			image: {},
			changed:false,
		}
	},
	computed: {
		localValue() {
			if ( this.value && this.value !== '' ) {
				let arr = [{ url: this.value }];

				return arr;
			}
			
			return '';
		},
	},
	methods: {
		onChangeInput() {
			if ( this.editable ) {
				this.changed = true;
			}
		},
		async fakeUploader( image ) {
			if ( this.editable ) {
				if( this.changed ) {
					this.image = image;
					this.imageChanged();
				}
				return Promise.resolve( { } );
			}
		},
		imageRemoved() {
			if ( this.editable ) {
				this.image = '';
				this.imageChanged();
				this.changed = true;
			}
		},
		imageChanged() {
			this.$emit( 'changed', this.image );
		},
	},
}
</script>

<style lang='scss' scoped>

.uploading-card {

	.input-wrapper {
		position: relative;

		button {
			font-family: 'Oswald', sans-serif;
			font-weight: 500;
			position: absolute;
			top: calc( 100% + 25px );
			left: 50%;
			transform: translateX(-50%);
			width: 180px;
		}
	}

	::v-deep .formulate-input {

		.formulate-input-wrapper {

			.formulate-input-element {
				max-width: 100%;

				.formulate-input-upload-area {
					min-height: 200px;
					margin-bottom: 100px;

					&[data-has-files] {

						input {
							display: block;
						}
					}

					input {
						top: calc( 100% + 25px );
						height: 43px;
						width: 180px;
						left: 50%;
						transform: translateX(-50%);
						cursor: pointer;

						&::-webkit-file-upload-button {
							cursor: pointer;
						}
					}

					.formulate-files {

						.formulate-file {
							flex-direction: column;
							border: none;
							padding: 0;

							.formulate-file-image-preview {
								width: 100%;
								height: auto;
								flex: 1 1 100%;
								position: static;
								box-shadow: none;

								&:hover {
									transform: scale(1)
								}

								img {
									position: static;
								}
							}

							.formulate-file-name {
								display: none;
							}

							.formulate-file-progress {
								display: none;
							}

							.formulate-file-remove {
								top: 10px;
								right: 10px;
								font-size: 1.5rem;
								z-index: 5;
							}
						}
					}
				}
			}
		}
	}
}

</style>